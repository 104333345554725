<template>
  <c-flex flex-dir="column">
    <HeaderComp />

    <c-box
      :max-w="['inherit', '1200px']"
      w="100%"
      mx="auto"
    >
      <c-flex
        justify-content="center"
        max-w="1200px"
      >
        <Sidemenu />
        <c-flex
          pt="42px"
          pl="30px"
          mb="130px"
          w="923px"
          flex-grow="1"
        >
          <router-view />
        </c-flex>
      </c-flex>
    </c-box>

    <FooterComp />
  </c-flex>
</template>

<script>
import HeaderComp from '@/components/header/index.vue'
import Sidemenu from './sidemenu.vue'
import FooterComp from '@/components/layouts/footer/index.vue'
import { mapState } from 'vuex'
export default {
  name: 'ProfileLayout',
  components: {
    HeaderComp,
    Sidemenu,
    FooterComp,
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
    }),
    isSuperadmin() {
      if (this.user.role !== 'super_admin') {
        this.$router.replace('/')
      }
      if (this.user.role == 'super_admin') return true
      return false
    },
  },
}
</script>

<style scoped>
[data-chakra-component="CBreadcrumb"] {
  color: var(--colors-primary-400);
}

[data-chakra-component="CBreadcrumb"]
  /deep/
  [data-chakra-component="CBreadcrumbItem"] {
  color: var(--colors-primary-400);
}
[data-chakra-component="CBreadcrumb"]
  /deep/
  [data-chakra-component="CBreadcrumbItem"]:last-child {
  color: #333;
}
</style>
