<template>
  <c-flex
    id="profileNavigation"
    as="nav"
    :position="['fixed', 'static']"
    :bottom="[0]"
    :h="['60px', 'auto']"
    :w="['100vw', 'auto']"
    background="white"
    align-items="center"
    justify-content="space-around"
    border-right="1px solid #f2f2f2"
    pr="18px"
    pb="60px"
    :box-shadow="['4px -4px 50px 5px rgba(0, 0, 0, 0.15)', 'none']"
    :d="['flex', 'block']"
    :z-index="[999, 0]"
    :pt="[null, '33px']"
  >
    <c-link
      v-for="menu in menus"
      :key="menu.name"
      as="router-link"
      :to="menu.route"
      class="menu-item"
      exact-active-class="active"
    >
      <c-flex
        v-chakra="{
          '.active &': {
            bg: [null, '#f5f5f5'],
          },
        }"
        :flex-direction="['column', 'row']"
        :justify-content="['center', 'flex-start']"
        align-items="center"
        :h="[null, '66px']"
        :w="[null, '257px']"
        :border-radius="[null, '12px']"
      >
        <c-box
          v-chakra="{
            '.active &': {
              bg: [null, 'primary.400'],
            },
          }"
          w="7px"
          h="100%"
          border-top-left-radius="15px"
          border-bottom-left-radius="15px"
        />
        <c-image
          class="icon"
          :alt="menu.name"
          :src="menu.icon"
          w="24px"
          h="24px"
          :ml="[null, '16px']"
        />
        <c-text
          v-chakra="{
            '&.active': {
              color: '#008C81',
            },
          }"
          :class="{ active: menu.isActive }"
          :font-size="['10px', '14px']"
          :line-height="['21px']"
          :font-weight="['500']"
          :mx="[null, '20px']"
          color="#555555"
          v-html="menu.name"
        />
      </c-flex>
    </c-link>
  </c-flex>
</template>

<script>
import { CFlex, CLink, CBox, CImage, CText } from '@chakra-ui/vue'

export default {
  name: 'SideMenu',
  components: {
    CFlex,
    CLink,
    CBox,
    CImage,
    CText,
  },
  computed: {
    menus() {
      const self = this
      return [
        {
          name: 'Dashboard',
          route: { name: 'superadmin' },
          get icon() {
            if (this.isActive) {
              return require('@/assets/icon-home-active.svg')
            } else {
              return require('@/assets/icon-home.svg')
            }
          },
          get isActive() {
            return self.$route.name === 'superadmin'
          },
        },
        {
          name: 'Manajemen Diet Program',
          route: { name: 'superadmin.diet-program' },
          get icon() {
            if (this.isActive) {
              return require('@/assets/icon-circles-active.svg')
            } else {
              return require('@/assets/icon-circles.svg')
            }
          },
          get isActive() {
            return self.$route.name.includes('superadmin.diet-program')
          },
        },
        {
          name: 'Manajemen Admin',
          route: { name: 'superadmin.admin' },
          get icon() {
            if (this.isActive) {
              return require('@/assets/icon-user-plus-active.svg')
            } else {
              return require('@/assets/icon-user-plus.svg')
            }
          },
          get isActive() {
            return self.$route.name.includes('superadmin.admin')
          },
        },
        {
          name: 'Manajemen Klien',
          route: { name: 'superadmin.clients' },
          get icon() {
            if (this.isActive) {
              return require('@/assets/icon-user-active.svg')
            } else {
              return require('@/assets/icon-user.svg')
            }
          },
          get isActive() {
            return self.$route.name.includes('superadmin.clients')
          },
        },
        {
          name: 'Manajemen Diet <br> Coaching & Challenge',
          route: { name: 'superadmin.coaching-challenge' },
          get icon() {
            if (this.isActive) {
              return require('@/assets/icon-run-active.svg')
            } else {
              return require('@/assets/icon-run.svg')
            }
          },
          get isActive() {
            return self.$route.name.includes('superadmin.coaching-challenge')
          },
        },
        {
          name: 'Manajemen Ahli Gizi',
          route: { name: 'superadmin.nutritionists' },
          get icon() {
            if (this.isActive) {
              return require('@/assets/icon-users-active.svg')
            } else {
              return require('@/assets/icon-users.svg')
            }
          },
          get isActive() {
            return self.$route.name.includes('superadmin.nutritionists')
          },
        },
        {
          name: 'Manajemen Notifikasi',
          route: { name: 'superadmin.notifikasi' },
          get icon() {
            if (this.isActive) {
              return require('@/assets/ic-bell-active.svg')
            } else {
              return require('@/assets/ic-bell.svg')
            }
          },
          get isActive() {
            return self.$route.name.includes('superadmin.notifications')
          },
        },
        {
          name: 'Manajemen Diet Kuesioner',
          route: { name: 'superadmin.diet-kuesioner' },
          get icon() {
            if (this.isActive) {
              return require('@/assets/icon-file-analytics-active.svg')
            } else {
              return require('@/assets/icon-file-analytics.svg')
            }
          },
          get isActive() {
            return self.$route.name.includes('superadmin.diet-kuesioner')
          },
        },
        {
          name: 'Manajemen Glossary',
          route: { name: 'superadmin.glossary' },
          get icon() {
            if (this.isActive) {
              return require('@/assets/icon-box-active.svg')
            } else {
              return require('@/assets/icon-box.svg')
            }
          },
          get isActive() {
            return self.$route.name.includes('superadmin.glossary')
          },
        },
        {
          name: 'Manajemen Log Activity',
          route: { name: 'superadmin.log-activity' },
          get icon() {
            if (this.isActive) {
              return require('@/assets/icon-activity-active.svg')
            } else {
              return require('@/assets/icon-activity.svg')
            }
          },
          get isActive() {
            return self.$route.name.includes('superadmin.log-activity')
          },
        },
        {
          name: 'Manajemen Kupon',
          route: { name: 'superadmin.coupons' },
          get icon() {
            if (this.isActive) {
              return require('@/assets/icon-percentage-active.svg')
            } else {
              return require('@/assets/icon-percentage.svg')
            }
          },
          get isActive() {
            return self.$route.name.includes('superadmin.coupons')
          },
        },
      ]
    },
  },
}
</script>

<style>
body {
  padding-bottom: 75px;
}
</style>
