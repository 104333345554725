var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "id": "profileNavigation",
      "as": "nav",
      "position": ['fixed', 'static'],
      "bottom": [0],
      "h": ['60px', 'auto'],
      "w": ['100vw', 'auto'],
      "background": "white",
      "align-items": "center",
      "justify-content": "space-around",
      "border-right": "1px solid #f2f2f2",
      "pr": "18px",
      "pb": "60px",
      "box-shadow": ['4px -4px 50px 5px rgba(0, 0, 0, 0.15)', 'none'],
      "d": ['flex', 'block'],
      "z-index": [999, 0],
      "pt": [null, '33px']
    }
  }, _vm._l(_vm.menus, function (menu) {
    return _c('c-link', {
      key: menu.name,
      staticClass: "menu-item",
      attrs: {
        "as": "router-link",
        "to": menu.route,
        "exact-active-class": "active"
      }
    }, [_c('c-flex', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '.active &': {
            bg: [null, '#f5f5f5']
          }
        },
        expression: "{\n        '.active &': {\n          bg: [null, '#f5f5f5'],\n        },\n      }"
      }],
      attrs: {
        "flex-direction": ['column', 'row'],
        "justify-content": ['center', 'flex-start'],
        "align-items": "center",
        "h": [null, '66px'],
        "w": [null, '257px'],
        "border-radius": [null, '12px']
      }
    }, [_c('c-box', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '.active &': {
            bg: [null, 'primary.400']
          }
        },
        expression: "{\n          '.active &': {\n            bg: [null, 'primary.400'],\n          },\n        }"
      }],
      attrs: {
        "w": "7px",
        "h": "100%",
        "border-top-left-radius": "15px",
        "border-bottom-left-radius": "15px"
      }
    }), _c('c-image', {
      staticClass: "icon",
      attrs: {
        "alt": menu.name,
        "src": menu.icon,
        "w": "24px",
        "h": "24px",
        "ml": [null, '16px']
      }
    }), _c('c-text', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '&.active': {
            color: '#008C81'
          }
        },
        expression: "{\n          '&.active': {\n            color: '#008C81',\n          },\n        }"
      }],
      class: {
        active: menu.isActive
      },
      attrs: {
        "font-size": ['10px', '14px'],
        "line-height": ['21px'],
        "font-weight": ['500'],
        "mx": [null, '20px'],
        "color": "#555555"
      },
      domProps: {
        "innerHTML": _vm._s(menu.name)
      }
    })], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }